<template>
  <div class="page" :data-comp="blok.component">
    <component
      v-for="component in blok.body"
      :key="component._uid"
      v-editable="component"
      :is="component.component"
      :blok="component"
    ></component>
  </div>
</template>

<script setup>
import { onActivated, onMounted } from "vue";
import { useThemeColor } from "@/composables/useThemeColor.js";
const { themeBgColor, themeTextColor } = useThemeColor();

defineProps({
  title: {
    type: String,
    default: "",
  },
  blok: {
    type: Object,
    default: () => ({}),
  },
});

onMounted(() => {
  setTheme();
});
onActivated(() => {
  setTheme();
});

const setTheme = () => {
  themeBgColor.value = null;
  themeTextColor.value = null;
};
</script>

<style lang="scss">
.page {
}
</style>
